'use client';
import { FC, ReactNode } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';

import { useIsClient } from './is-client.context';

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '@/constants/config.constants';

export const LoginAuth0Provider: FC<{ children: ReactNode }> = ({ children }) => {
  const isClient = useIsClient();

  if (!isClient) {
    // prevent rendering on server
    return null;
  }

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
};
